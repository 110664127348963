body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 375px;
  font-size: 16px;
  line-height: 1.42;
  color: #0D0D0D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Poppins', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 15px;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none;
}

img {
  max-width: 100%;
  vertical-align: top;
}

a {
  color: #DB2955;
  text-decoration: none;
}

a:hover {
  color: inherit;
}

button:focus {
  outline: none;
}